import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages() {
    const countryCode = process.env.VUE_APP_COUNTRY_CODE || "KZ";
    // 1. Загружаем все файлы из locales/ (кроме country/)
    const generalLocales = require.context("../locales", true, /^(?!.*\/country\/).*\.json$/i); // Исключает "country/"

    // 3. Берем пеерводы от страны
    let countryLocales;
    try {
        if (countryCode === "RU") {
            countryLocales = require.context("../locales/country/RU", false, /\.json$/i);
        } else if (countryCode === "KZ") {
            countryLocales = require.context("../locales/country/KZ", false, /\.json$/i);
        } else {
            countryLocales = null;
        }
    } catch (e) {
        console.warn(`No locale files found for country: ${countryCode}`);
    }


    const messages = {};

    const loadMessages = (context) => {
        if (!context) return;
        context.keys().forEach((key) => {
            const locale = key.replace(/(\.\/|\.json)/g, "");
            messages[locale] = Object.assign({}, messages[locale], context(key)); // Объединяем данные
        });
    };

    // 3. Загружаем файлы
    loadMessages(generalLocales);
    loadMessages(countryLocales);
    return messages;
}

if (process.env.VUE_APP_I18N_LOCALE) {
    document.documentElement.lang = process.env.VUE_APP_I18N_LOCALE
}

export default new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
    messages: loadLocaleMessages()
});
