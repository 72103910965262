import _ from 'lodash'
import router from '@/plugins/router'


/**
 *
 @param a
 @param b
 @param ignoreQueryParams
 @param ignoreParams
 @param console
 @returns {false|boolean|*}
 */
export function isSameRouteWithQueryParams(a, b, {
    ignoreQueryParams = false,
    ignoreParams = false,
    c = false
} = {}) {
    if(c) {
        console.log('Route A:', a);
        console.log('Route B:', b);
    }

    if (!a || !b) return false;

    // Проверяем имена, если они есть у обоих объектов
    if (a.name && b.name) {
        if (a.name !== b.name) {
            if(c) console.log('Different names:', a.name, b.name);
            return false;
        }
    }
    // Если только один объект имеет имя, а другой нет
    else if ((a.name && !b.name) || (!a.name && b.name)) {
        if(c) console.log('One route has name, other doesn\'t');
        return false;
    }

    // Если имена не определены, проверяем пути
    if (!a.name && !b.name) {
        if (a.path && b.path) {
            if (a.path !== b.path) {
                if(c) console.log('Different paths:', a.path, b.path);
                return false;
            }
        } else {
            if(c) console.log('No names and not both have paths');
            return false;
        }
    }

    // Проверяем параметры, если они есть
    if(!ignoreParams && a.params && b.params) {
        if (!_.isEqual(a.params, b.params)) {
            if(c) console.log('Different params');
            return false;
        }
    }

    // Проверяем query параметры
    if (!ignoreQueryParams) {
        const aQuery = a.query || {};
        const bQuery = b.query || {};
        if (!_.isEqual(aQuery, bQuery)) {
            if(c) console.log('Different query params');
            return false;
        }
    }

    return true;
}

export function isRouteChildrenOf(route, parentRoute)
{
    const a = router.resolve(route).href;
    const b = router.resolve(parentRoute).href;

    return b.startsWith(a);
}

export function buildTree(elements, parentId = 0, idKeyName = 'id', parentIdKeyName = 'parent_id', childrenKeyName = 'children') {
    let branch = [];

    elements.forEach(element => {
        if (element[parentIdKeyName] === parentId) {
            let children = buildTree(elements, element[idKeyName], idKeyName, parentIdKeyName, childrenKeyName);
            if (children.length) {
                element[childrenKeyName] = children;
            }
            branch.push(element);

            elements = elements.filter(e => e[idKeyName] !== element[idKeyName]);
        }
    });

    return branch;
}

export function buildListArrayFromTreeArray(arTree, childrenKeyName = 'children') {
    let arResult = [];

    arTree.forEach(arItem => {
        let tmpItem = { ...arItem };
        delete tmpItem[childrenKeyName];

        arResult.push(tmpItem);
        if (arItem[childrenKeyName]) {
            arResult.push(...buildListArrayFromTreeArray(arItem[childrenKeyName]));
        }
    });

    return arResult;
}
