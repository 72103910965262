<template>
    <header class="header">
        <div class="header__left-side">
            <div class="logo cursor_pointer" v-if="$vuetify.breakpoint.smAndUp">
                <template v-if="$route.name === 'uved'">
                    <img class="compact" :src="`/img/${logoDir}/logo.svg`" alt="leen-tech-logo"/>
                </template>
                <template v-else>
                    <img class="compact" :src="`/img/${logoDir}/logo.svg`" @click="goToMenu(home)"
                         alt="qazalpack-logo"/>
                </template>
            </div>

            <div class="nav-toggle__wrap" v-else>
                <button class="nav-toggle" :class="{'active': mobileMenuActive}" @click="navToggle">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div>

            <div class="search-bar" v-if="$vuetify.breakpoint.smAndUp">
                <ValidationObserver tag="div" ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <ValidationProvider tag="div" ref="search_text" rules="min:1|max:255" v-slot="{ errors, valid }">
                        <TextInput
                            v-model="search_value"
                            type="text"
                            :error-messages="errors"
                            :disabled="userBlock || loading"
                            :label="$t('search')"
                            color="primary"
                            hide-details
                            hide-label
                            solo
                            flat
                            class="search-input"
                            clearable
                            ref="search_text_field"
                            @keypress.enter="search"
                            @click="onSearchInputFocus"
                            autocomplete="off"
                            id="search-input"
                        ></TextInput>
                    </ValidationProvider>
                </ValidationObserver>

                <div class="search-bar__result">
                    <SearchResult
                        v-if="showSearch"
                        :searchText="search_text"
                        @itemClick="hideSearch"
                        v-click-outside="hideSearch"
                    />
                </div>
            </div>
        </div>

        <div class="header__center" v-if="!$vuetify.breakpoint.smAndUp">
            <Teleport to="#app" v-if="showSearch">
                <div class="mobile-search">
                    <div class="mobile-search__header">
                        <ValidationObserver tag="div" ref="observer" v-slot="{ invalid, validated, passes, validate }">
                            <ValidationProvider tag="div" ref="search_text" rules="min:1|max:255"
                                                v-slot="{ errors, valid }">
                                <TextInput
                                    v-model="search_value"
                                    type="text"
                                    :error-messages="errors"
                                    :disabled="userBlock || loading"
                                    :label="$t('search')"
                                    hide-label
                                    class="mobile-search__input"
                                    ref="search_text_field"
                                    @keypress.enter="search"
                                    autocomplete="off"
                                    id="search-input"
                                >
                                    <template v-slot:prepend-inner>
                                        <div class="mobile-search__close">
                                            <i class="icon icon-arrow-left" @click="toggleSearch"></i>
                                        </div>
                                    </template>
                                </TextInput>
                            </ValidationProvider>
                        </ValidationObserver>
                        <SearchResult
                            class="mobile-search__result"
                            :search-text="search_text"
                            @itemClick="hideSearch"
                        />
                    </div>
                </div>
            </Teleport>
        </div>

        <div class="header__right">
            <div class="d-flex justify-end logo-close">
                <div class="personal-menu">
                    <RouterLink :to="{name: 'profile'}" class="btn icon-btn profile-link">
                        <i class="icon icon-gear"></i>
                    </RouterLink>

                    <span v-if="!$vuetify.breakpoint.smAndUp" class="search-link btn icon-btn" @click="toggleSearch">
                        <i class="icon icon-search"></i>
                    </span>

                    <RouterLink :to="{ name: 'discuss_chat' }" class="btn icon-btn">
                        <i class="icon icon-discussions"></i>
                        <span v-if="notificationGroups['discuss_chat'] > 0" class="status-led status-led--red"></span>
                    </RouterLink>

                    <span class="btn icon-btn" @click="isMessagesPopupVisible = !isMessagesPopupVisible">
                        <i class="icon icon-messages"></i>
                        <span v-if="unreadMessages.length > 0" class="status-led status-led--red"></span>
                    </span>

                    <span class="btn icon-btn" @click="isNotificationPopupVisible = !isNotificationPopupVisible">
                        <i class="icon icon-notifications"></i>
                        <span v-if="unreadNotifications.length > 0" class="status-led status-led--red"></span>
                    </span>

                    <OpenAIChat></OpenAIChat>

                    <Teleport to="#app">
                        <NotificationPopup
                            v-model="isNotificationPopupVisible"
                            @close="isNotificationPopupVisible = false"
                            @item-click="notificationClick"
                            :items="notificationsForPopup"
                            :all-items-link="{name: 'notifications'}"
                            :all-items-label="$t('all-notifications')"
                        ></NotificationPopup>
                    </Teleport>
                    <Teleport to="#app">
                        <NotificationPopup
                            v-model="isMessagesPopupVisible"
                            @close="isMessagesPopupVisible = false"
                            @item-click="messageClick"
                            :items="messagesForPopup"
                            :all-items-link="{name: 'messages'}"
                            :all-items-label="$t('all-messages')"
                        ></NotificationPopup>
                    </Teleport>
                </div>
                <v-menu open-on-click left top max-width="380px" v-if="currentStatus" style="z-index: 201 !important;">
                    <template v-slot:activator="{ on, attrs }">

                        <div class="main-avatar" v-bind="attrs" v-on="on" style="z-index: 201 !important;">
                            <v-badge
                                :color="currentStatus.color"
                                dot
                                bottom
                                left
                                :overlap="true"
                                bordered
                            >
                                <v-avatar :size="40" @click="getWorkTime();getWorkTimeNotClosed()">
                                    <img :src="userAvatar" :alt="userName">
                                </v-avatar>
                            </v-badge>
                        </div>
                    </template>
                    <v-list subheader two-line class="mt-0 pt-2">
                        <v-list-item class="px-0 py-0 my-0 min_height_auto">
                            <v-list-item-content class="py-0 px-5 title_subtitle">
                                <v-list-item-title v-text="userName"></v-list-item-title>
                                <v-list-item-subtitle v-text="userEmployeePosition"></v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-avatar size="50" class="border_blue my-0 py-0">
                                <img v-if="userAvatar !== '/img/avatar.png'" :src="userAvatar" :alt="userName">
                                <v-icon v-else color="primary" size="40">
                                    ${{ 'settingsIcon' }}
                                </v-icon>
                            </v-list-item-avatar>
                        </v-list-item>
                        <v-list-item v-if="!WorkDayStart && businessDayEntrance === 'true'" class="py-5 min_height_auto">
                            <div v-if="not_closed.length > 0" class="mx-0 px-0 pt-2" style="width: 100%">
                                <div class="text-center pt-2" style="width: 100%" v-for="(item, i) in not_closed"
                                     :key="'closed_'+i">
                                    <Btn color="primary" @click="closeWorkingDay(item)">
                                        {{ $t('get_to_work') }}
                                    </btn>
                                    <div class="mr-auto cursor-pointer" @click="closeWorkingDay(item)" v-if="false">
                                        {{ $t('close_working_day') }}
                                    </div>
                                    <div style="color: red" v-if="false">
                                        {{ item.start_time }}
                                    </div>

                                </div>
                            </div>
                            <div v-if="not_closed.length === 0" class="text-center pt-2" style="width: 100%">
                                <Btn color="primary" @click="openWorkDay('start')">
                                    {{ $t('get_to_work') }}
                                </btn>
                            </div>
                        </v-list-item>
                    </v-list>

                    <v-list v-if="businessDayEntrance === 'false'" class="py-0">
                        <MenuListItem @changeLanguage="changeLanguage"/>
                    </v-list>
                    <v-list v-else-if="WorkDayStart && businessDayEntrance === 'true'" class="py-0">
                        <MenuListItem @changeLanguage="changeLanguage"/>
                        <v-list-item-content v-if="WorkDayStart" class="py-0 my-0">
                            <v-card>

                                <v-card-title class="pl-4 pr-3 py-0 my-0">
                                    <div class="d-flex pt-1" style="width: 100%">
                                        <div class="mr-auto">{{ $t('working_day_length') }}</div>
                                        <div>{{ work_duration }}</div>
                                    </div>
                                </v-card-title>
                                <v-divider></v-divider>

                                <v-card-text class="py-3">

                                    <div v-if="WorkDayStart" class="mx-0 px-0 my-0 py-0">
                                        <div class="mx-0 px-0 my-0 py-0"
                                             v-for="(item, index) in workDayStatus"
                                             :key="'workDayStatus-'+index"
                                             @click="updateWorkDayStatus(item)"
                                             v-show="item.id !== 6"
                                             :elevation="0"
                                        >
                                            <div class="v-list-item__title pb-1">
                                                <v-badge
                                                    :color="item.color"
                                                    dot
                                                    left
                                                    offset-y="12"
                                                    offset-x="8"
                                                    class="mt-2"
                                                >
                                                    <span class="ml-4 mt-0 cursor-pointer"> {{ item.name }}</span>
                                                </v-badge>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>

                                        <div class="text-center" style="width: 100%">
                                            <Btn color="primary" @click="openWorkDay('start')">
                                                {{ $t('get_to_work') }}
                                            </btn>
                                        </div>

                                    </div>


                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-text class="pt-3 pb-3">
                                    <div class="text-center py-0" style="width: 100%">
                                        <Btn color="primary" @click="openWorkDay('end')">
                                            {{ $t('complete_work') }}
                                        </btn>
                                    </div>
                                </v-card-text>

                                <v-divider v-if="not_closed.length > 0"></v-divider>
                                <v-card-text v-if="not_closed.length > 0">
                                    <div class="d-flex pb-2 v-list-item__title" v-for="(item, i) in not_closed"
                                         :key="'closed_'+i">
                                        <div class="mr-auto cursor-pointer" @click="closeWorkingDay(item)">
                                            {{ $t('close_working_day') }}
                                        </div>
                                        <div style="color: red">
                                            {{ item.start_time }}
                                        </div>

                                    </div>
                                </v-card-text>

                            </v-card>
                        </v-list-item-content>
                    </v-list>


                </v-menu>
            </div>
        </div>

        <v-dialog
            v-model="dialogCameraCapture"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card height="100%">
                <v-card-title v-if="false" class="headline">Сделайте снимок</v-card-title>

                <v-card-text style="height: 100%" class="mx-0 px-0 my-0 py-0">
                    <CameraCapture @photo-close="dialogCameraCapture=false; closeDialogCloseDay()"
                                   @photo-taken="handlePhotoTaken"
                                   :dialog="dialogCameraCapture"/>
                </v-card-text>


            </v-card>
        </v-dialog>


        <v-dialog
            v-model="dialogCloseDay"
            scrollable
            persistent
            transition="dialog-bottom-transition"
            max-width="500px"
        >
            <v-card>
                <ValidationObserver ref="observer_close_day" v-slot="{ invalid, validated, passes, validate }">
                    <v-toolbar class="dialog_toolbar" elevation="0">
                        <div class="ma-auto ml-0">
                            <span>{{ $t('close_day') }}</span>
                        </div>
                        <div>
                            <v-icon
                                @click="dialogCloseDay = false;close_day_uuid=null;close_day_comment=null;close_day_time=null">
                                mdi-close
                            </v-icon>
                        </div>
                    </v-toolbar>
                    <v-card-text>

                        <v-row>

                            <v-col v-if="false" cols="12">
                                <v-menu
                                    ref="menu"
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    :return-value.sync="close_day_time"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">

                                        <ValidationProvider ref="deal_status" rules="required|min:1"
                                                            v-slot="{ errors, valid }">
                                            <TextInput
                                                v-model="close_day_time"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"

                                                id="close_day_time"
                                                :label="$t('closing_time_of_working_day')"
                                                :error="!valid"
                                                :error-messages="errors"
                                                clearable


                                            ></TextInput>
                                        </ValidationProvider>

                                    </template>
                                    <v-time-picker
                                        v-if="menu2"
                                        v-model="close_day_time"
                                        @click:minute="$refs.menu.save(close_day_time)"
                                        format="24hr"
                                    ></v-time-picker>
                                </v-menu>
                            </v-col>
                            <v-col>
                                <ValidationProvider ref="deal_status" rules="required|min:1|max:10000"
                                                    v-slot="{ errors, valid }">
                                    <Textarea
                                        id="close_day_comment"
                                        v-model="close_day_comment"
                                        :label="$t('reason_why_it_was_nоt_closed_on_time')"
                                        :error="!valid"
                                        :error-messages="errors"
                                    ></Textarea>
                                </ValidationProvider>
                            </v-col>
                        </v-row>

                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" :disabled="invalid || loading" text @click="openWorkDay('end')">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </ValidationObserver>
            </v-card>
        </v-dialog>

    </header>
</template>

<script>
import {mapGetters, mapActions, mapState} from "vuex"
import NotificationPopup from "@/components/NotificationPopup.vue";
import TextInput from "@/components/Form/TextInput.vue";
import Textarea from "@/components/Form/Textarea.vue";
import SearchResult from "@/components/SearchResult.vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import Teleport from "vue2-teleport";
import CameraCapture from '@/components/CameraCapture.vue';
import Btn from "@/components/Form/Btn.vue";
import MenuListItem from '@/components/Leentech/MenuListItem.vue';
import OpenAIChat from "@/components/OpenAI/Chat.vue";

export default {
    name: "AppTopMenu",
    inject: ['forceRerender'],
    components: {
        ValidationProvider,
        ValidationObserver,
        SearchResult,
        TextInput,
        Textarea,
        NotificationPopup,
        Teleport,
        CameraCapture,
        Btn,
        MenuListItem,
        OpenAIChat
    },
    props: {
        showAppBar: Boolean,
        mobileMenuActive: Boolean
    },
    data() {
        return {
            items: [],
            menuClientItems: [],
            scrollAmount: 0,
            btn_prev: false,
            btn_next: false,
            home: {name: "uved"},
            showSearch: false,

            loading: false,
            search_text: '',
            search_value: '',
            searchX: 0,
            isNotificationPopupVisible: false,
            isMessagesPopupVisible: false,
            dialogCameraCapture: false,
            work_day_type: null,
            workDayStatus: [],
            currentStatus: null,
            work_duration: '0:00',
            not_closed: [],
            close_day_uuid: null,
            dialogCloseDay: false,
            close_day_time: null,
            close_day_comment: null,
            menu2: false,
        };
    },
    computed: {
        ...mapGetters([
            'lang',
            'language',
            'userEmployeePosition',
            'userName',
            'userAvatar',
            'userBlock',
            'unreadNotifications',
            'notifications',
            'WorkDayStatusId',
            'WorkDayStart',
            'businessDayEntrance'
        ]),

        ...mapState(['notificationGroups']),

        ...mapGetters('messages', [
            'unreadMessages',
            'messages'
        ]),

        logoDir() {
            return process.env.VUE_APP_LOGO_DIR ?? 'logo'
        },

        appBarStyle() {
            return {
                'height': 'auto',
                'width': '100%',
                'min-height': this.showAppBar ? "90px" : "40px",
            };
        },

        routePath() {
            return this.$route.path
        },

        notificationsForPopup() {
            return this.notifications
        },

        messagesForPopup() {
            let items = [];
            this.messages.forEach((item, index) => {
                items.push({
                    message: item.comment.value,
                    read: item.is_readed,
                    ...item
                });
            });

            return items
        }
    },
    async mounted() {
        await this.menuClientNavigation();
        await this.getWorkDayStatus();
    },
    watch: {
        WorkDayStatusId(newVal, oldVal) {
            this.currentStatus = this.workDayStatus.find(
                (status) => status.id === this.WorkDayStatusId
            );
        },
    },
    methods: {
        ...mapActions(['setWorkDayStatusId', 'setWorkDayStart', 'setSettings', 'setLanguage', 'setUserName', 'userInitials', 'setEmployeePosition', 'setUserAvatar', 'fetchNotifications', 'notificationRead']),

        openWorkDay(type) {
            this.work_day_type = type;
            this.dialogCameraCapture = true
        },

        closeWorkingDay(item) {
            this.close_day_uuid = item.uuid;
            this.dialogCloseDay = true

        },

        async workDay(data) {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.languages && this.languages[this.tab]) {
                formData.append('language', this.languages[this.tab])
            }

            if (this.close_day_comment) {
                formData.append('comment', this.close_day_comment)
            }

            // if (this.close_day_time) {
            //     formData.append('end_time', this.close_day_time)
            // }

            if (this.close_day_uuid) {
                formData.append('end_time', '13:00')
                formData.append('close_day_uuid', this.close_day_uuid)
            }


            if (data.geo_data && data.geo_data.latitude) {
                formData.append('latitude', data.geo_data.latitude)
            } else {
                this.$toastr.error(this.$t('wait_until_coordinates_of_the_location_are_determined'))
                this.dialogCloseDay = false;
                this.close_day_uuid = null;
                this.close_day_time = null;
                this.close_day_comment = null;
                return;
            }
            if (data.geo_data && data.geo_data.longitude) {
                formData.append('longitude', data.geo_data.longitude)
            }

            if (data.address) {
                formData.append('location', data.address)
            }

            if (data.photo) {
                if (data.photo.length > 250) {
                    var mimeType = this.getMimeType(data.photo)
                    var blob = this.dataURL64toBlob(data.photo)
                    if (mimeType && blob) {
                        formData.append('photo', blob, mimeType)
                    }
                } else {
                    this.$toastr.error(this.$t('take_photo'))
                    return;
                }
            }

            //  this.work_day_type = 'start';
            await this.$http
                .post(`admin/work_day/${this.work_day_type}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('work_day_has_been_changed'))
                    if (this.close_day_uuid) {
                        this.setWorkDayStart(true);
                    } else {
                        if (this.work_day_type === 'start') {
                            this.setWorkDayStart(true);

                        } else {
                            this.setWorkDayStart(false);
                        }

                    }
                    if (res && res.body && res.body.data && res.body.data.work_day_status_id) {
                        this.setWorkDayStatusId(res.body.data.work_day_status_id);
                    }


                })
                .catch(err => {
                    this.$toastr.error(this.$t('work_day_has_not_been_changed'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false

                    this.dialogCloseDay = false;
                    this.close_day_uuid = null;
                    this.close_day_time = null;
                    this.close_day_comment = null;
                })

        },

        handlePhotoTaken(data) {
            // Закрываем диалог после получения фото
            this.dialogCameraCapture = false;
            // Вставьте код для отправки фото в базу данных через API
            this.workDay(data);
        },

        closeDialogCloseDay() {
            this.dialogCloseDay = false;
            this.close_day_uuid = null;
            this.close_day_time = null;
            this.close_day_comment = null;
        },

        async getWorkTime() {
            this.loading = true;

            let params = {};
            if (this.WorkDayStart) {
                await this.$http
                    .get("admin/work_time", {
                        params: params,
                    })
                    .then(res => {
                        this.work_duration = res.body.data.work_duration
                    })
                    .catch(err => {
                        this.trackings = []
                        this.$toastr.error(this.$t('failed_to_get_list_work_day_status'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }

        },

        async getWorkTimeNotClosed() {
            this.loading = true;

            let params = {};

            await this.$http
                .get("admin/work_day_not_closed", {
                    params: params,
                })
                .then(res => {
                    this.not_closed = res.body.data
                })
                .catch(err => {
                    this.not_closed = []
                    this.$toastr.error(this.$t('failed_to_get_list_work_day_status'))
                })
                .finally(end => {
                    this.loading = false
                })


        },

        async getWorkDayStatus() {

            this.loading = true;

            let params = {};

            await this.$http
                .get("admin/work_day_status", {
                    params: params,
                })
                .then(res => {
                    this.workDayStatus = res.body.data
                    this.currentStatus = this.workDayStatus.find(
                        (status) => status.id === this.WorkDayStatusId
                    );

                })
                .catch(err => {
                    this.trackings = []
                    this.$toastr.error(this.$t('failed_to_get_list_work_day_status'))
                })
                .finally(end => {
                    this.loading = false
                })
        },

        async updateWorkDayStatus(item) {

            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.languages && this.languages[this.tab]) {
                formData.append('language', this.languages[this.tab])
            }

            formData.append('work_day_status', item.id)


            await this.$http
                .post(`admin/work_day/update_status`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('work_day_status_has_been_updated'))
                    this.currentStatus = this.workDayStatus.find(
                        (status) => status.id === item.id
                    );
                    this.setWorkDayStatusId(item.id);
                })
                .catch(err => {
                    this.$toastr.error(this.$t('work_day_status_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        },

        notificationClick(item) {
            if (item.read === 0) {
                this.notificationRead(item)
            }

            if (item.data && item.data.type && item.data.route) {
                if (item.data.route !== '') {
                    this.$router.push({
                        name: item.data.route,
                        params: item.data.params
                    })
                }
            }

            this.isNotificationPopupVisible = false;
        },

        messageClick(item) {
            if (item.messageable_type == 'task') {
                this.$router.push({
                    name: 'task.show',
                    params: {
                        id: item.messageable_uuid
                    }
                })
            } else if (item.messageable_type == 'deal') {
                this.$router.push({
                    name: 'deal.show',
                    params: {
                        id: item.messageable_uuid
                    }
                })
            } else if (item.messageable_type == 'uved') {
                this.$router.push({
                    name: 'uved.show',
                    params: {
                        id: item.messageable_uuid
                    }
                })
            }

            this.isMessagesPopupVisible = false;
        },

        toggleSearch() {
            this.showSearch = !this.showSearch
        },

        menuClientNavigation() {
            this.menuClientItems = [
                {
                    title: 'profile',
                    icon: "mdi-account",
                    to: {
                        name: 'profile'
                    },
                    visible: this.$auth.check(),
                    action: this.forceRerender,
                    exact: false
                },
                {
                    title: 'logout',
                    icon: "mdi-logout-variant",
                    visible: this.$auth.check(),
                    action: this.logout
                }
            ]
        },

        goToMenu(route) {
            this.$router.push(route)
        },

        async logout() {
            await this.$auth
                .logout({
                    makeRequest: true,
                    redirect: {
                        name: "login"
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('you_have_successfully_logged_out'))
                })
                .catch(err => {
                    this.$toastr.error(this.$t('error_occurred_while_exiting'))
                })
        },

        getCookie(name) {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        },

        async setLang() {
            let language = this.getCookie('language')
            if (language == null && this.$auth.check() && this.$auth.user() && this.$auth.user().language) {
                document.documentElement.lang = this.$auth.user().language

            } else {
                document.documentElement.lang = language
            }
            this.$vuetify.lang.current = document.documentElement.lang
            this.$i18n.locale = document.documentElement.lang
            this.setLanguage(document.documentElement.lang)
            if (typeof this.$i18n.locale !== 'undefined') {
                this.$i18n.locale = document.documentElement.lang
            }
            this.$moment.locale(this.$i18n.locale)
            if (typeof this.$vuetify.lang.current !== 'undefined') {
                this.$vuetify.lang.current = document.documentElement.lang
            }
            this.forceRerender()
        },

        async changeLanguage(lng, reload) {

            if (this.$auth && this.$auth.user() && this.$auth.user().id) {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()

                if (lng) {
                    formData.append('language', lng)
                }
                // Update
                await this.$http
                    .put(`admin/language/${this.$i18n.locale}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.locale = lng
                        document.documentElement.lang = lng
                        this.$vuetify.lang.current = lng
                        this.$i18n.locale = lng
                        this.setLanguage(lng)
                        this.forceRerender()
                        this.setCookie('language', lng, 365)
                    })
                    .catch(err => {
                        // console.log(err.data);
                        this.$toastr.error(this.$t('language_has_not_been_updated'));
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })

            } else {
                this.locale = lng
                document.documentElement.lang = lng
                this.$vuetify.lang.current = lng
                this.$i18n.locale = lng
                this.setLanguage(lng)
                this.forceRerender()
                this.setCookie('language', lng, 365)
            }


        },

        setCookie(name, value, days) {
            var expires = "";
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = name + "=" + (value || "") + expires + "; path=/";
        },

        navToggle(e) {
            e.preventDefault();
            this.$emit('nav-toggle');
        },

        search() {
            this.search_text = this.search_value;
            this.showSearch = true;
            this.searchX = this.$refs.search_text_field.$el.getBoundingClientRect().left
        },

        hideSearch() {
            this.showSearch = false;
        },

        onSearchInputFocus() {
            if (this.search_value.length > 0) {
                this.showSearch = true;
            }
        }
    },


};
</script>

<style lang="scss">
@import "@/scss/variables";

.logo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 40px;

    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
    }

    &.leen-logo {
        padding: 0;
        overflow: hidden;

        .icon-logo-compact {
            width: 32px;
            height: 32px;
            font-size: 32px;
            color: var(--primary);
            display: block;

            :before {
                display: block;
            }
        }
    }
}

.header {
    background-color: #fff;
    display: flex;
    gap: $grid-gutter;
    height: var(--header-height-mobile);

    padding-left: $grid-gutter;
    padding-right: $grid-gutter;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
        padding-left: calc(51px + #{$grid-gutter * 1.5});
        padding-right: $grid-gutter * 1.5;
        height: var(--header-height);
    }
}


.search-result__content {
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
        height: 500px;
    }
}

.header__left-side {
    display: flex;
    gap: calc(51px + #{$grid-gutter * 1.5});
    align-items: center;
    flex-grow: 1;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {

    }
}

.header__center {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header__right {
    display: grid;
    grid-template-columns: 1fr auto;
    width: 400px;
}

.main-avatar {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #fff;
}

.main-avatar__desc {
    padding-right: 15px;
    text-align: right;
    display: none;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
        display: block;
    }
}

.main-avatar__name {
    font-weight: bold;
}

.main-avatar__position {
    color: var(--primary-light)
}

.personal-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0 $grid-gutter 0 0;

    .btn {
        margin-right: 20px;
        color: var(--primary);

        &:last-child {
            margin-right: 0;
        }
    }

    .profile-link {
        display: none;

        @media #{map-get($display-breakpoints, 'sm-and-up')} {
            display: block;
        }
    }
}

.icon-btn {
    padding: 0;
    margin: 0;
    background-color: transparent;
    position: relative;
    text-decoration: none;
    display: inline-block;

    &:hover {
        text-decoration: none;
    }

    i {
        display: block;
        font-size: 20px;

        &:before {
            display: block;
        }
    }

    img, svg {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.status-led {
    position: absolute;
    bottom: -3px;
    right: -3px;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    border: 3px solid #fff;
}

.status-led--red {
    background-color: red;
}

.status-led--green {
    background-color: green;
}

.nav-toggle__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 16px;
    //width: calc(var(--header-height-mobile));
    height: calc(var(--header-height-mobile));
    //position: absolute;
    //top: 0;
    //left: 100%;
    z-index: 100;

    //&:has(.active) {
    //    left: $side-width
    //}

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
        display: none;
        width: calc(var(--header-height));
        height: calc(var(--header-height));
    }
}

.nav-toggle {
    display: block;
    width: 28px;
    height: 28px;
    transition: all ease 300ms;
    position: relative;
    border: 2px solid var(--primary);
    border-radius: 100%;
    padding: $spacer;

    span {
        height: 2px;
        background-color: var(--primary);
        width: 50%;
        margin-left: 10%;
        margin-right: 10%;
        display: block;
        position: absolute;
        transition: all ease 300ms;

        &:nth-child(1) {
            top: calc(35% - 1px);
        }

        &:nth-child(2) {
            top: calc(50% - 1px);
        }

        &:nth-child(3) {
            top: calc(65% - 1px);
        }
    }

    &.active {
        span {
            &:nth-child(1) {
                transform: rotate(45deg);
                top: 50%;
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
                top: 50%;
            }
        }
    }
}

.search-bar {
    width: 100%;
    position: relative;
}

@import 'vuetify/src/styles/tools/_index';

.search-bar__result {
    display: none;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
        display: block;
        position: absolute;
        z-index: 100;
        top: calc(100% + #{$grid-gutter / 2});
        width: 100%;
        box-shadow: $box-shadow;
        border-radius: $border-radius-root;
    }
}

.mobile-search {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 200;
}

.mobile-search__header {
    background-color: #fff;
    padding: $grid-gutter / 2 $grid-gutter;
    height: var(--header-height-mobile);
}

.mobile-search__close {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    cursor: pointer;
    color: var(--primary);
    padding-right: $grid-gutter / 4;

    i {
        display: block;
        height: 16px;
        font-size: 16px;
    }
}

.mobile-search__result {
    position: absolute;
    bottom: 0;
    top: var(--header-height-mobile);
    right: 0;
    left: 0;

    .search-result__content {
        max-height: calc(100vh - var(--header-height-mobile) - 48px - #{$grid-gutter / 2});
    }
}
</style>
