import TableSettings from "@/components/TableSettings.vue";
import SortableView from "@/plugins/mixins/SortableView";

let ResourceView = {
    components: {
        TableSettings,
    },

    mixins: [
        SortableView
    ],

    data() {
        return {
            fields: [],
            listForm: null,
            tableColumns: [],
        }
    },

    methods: {
        async fetchListForm() {
            this.loading = true;

            await this.$http({
                method: 'get',
                url: `admin/${this.entityName}/list_form`
            })
                .then(res => {
                    if (res.status === 200) {
                        this.listForm = res.body;
                        this.listForm.fields.map((item) => {
                            return {
                                ...item,
                                width: item.width === 0 ? 'auto' : item.width
                            }
                        });
                    }
                }).catch(() => {
                    this.loading = false;
                }).finally(() => {
                    this.loading = false;
                })
        },

        async fetchFields() {
            await this.$http.get(`admin/${this.entityName}/resource`)
                .then(res => {
                    this.fields = res.body.fields;
                })
                .catch((err, resp) => {
                    this.$toastr.error(err.body.message);
                })
        },

        onColumnsMoved(columns) {
            this.save(columns, true);
        },

        onColumnResized(columns) {
            console.log(columns)
            this.save(columns);
        },

        onColumnSelect(columns) {
            console.log(columns)
            this.tableColumns
                .map((item) => {
                    item.active = !!columns.includes(item.name);
                    return item;
                });

            this.$set(this, 'tableColumns', this.tableColumns);
            this.save(this.tableColumns);
        },

        save(columns) {
            let data = {}
            this.loading = true;

            columns.forEach((item, index) => {
                if (item.active) {
                    data[item.prop] = {
                        sort: item.sort,
                        width: item.width === 'auto' ? 0 : item.width
                    };
                }
            });

            this.$http.post(`admin/${this.entityName}/list_form`, {
                fields: data
            }).then((resp) => {
                // this.listForm = resp.data;
                this.$toastr.success(this.$t('columns_saved'));
            }).catch(() => {

            }).finally(() => {
                this.loading = false;
            })
        },

        getTableColumns(fields, listForm = null) {
            let columns = [];

            fields.forEach((field, index) => {
                if (field.onlyFilter) return;

                let active = field.default;
                let sort = index;
                let width = 'auto';

                if (listForm) {
                    let currentField = listForm.fields.find((item) => {
                        return field.name === item.name;
                    });

                    if (currentField) {
                        active = true;
                        sort = currentField.sort;

                        if (currentField.width > 0) {
                            width = currentField.width;
                        }
                    } else {
                        active = false;
                    }
                }

                let col = {
                    ...field,
                    prop: field.name,
                    label: this.$t(`columns.${this.entityName}.${field.label}`),
                    active: active,
                    sort: sort,
                    width: width
                };

                columns.push(col);
            });

            return columns;
        }
    },

    mounted() {
        this.fetchFields()
            .then(() => {
                this.fetchListForm()
                    .then(() => {
                        this.tableColumns = this.getTableColumns(this.fields, this.listForm);
                    })
            });
    }
}

export default ResourceView;
