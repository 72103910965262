<script>
export default {
    name: "Input",

    model: {
        prop: 'value',
        event: 'input'
    },

    props: {
        value: {
            type: [String,Number],
            required: false,
            default: ''
        },
        label: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        hideLabel: {
            type: Boolean,
            default: false
        },
        hint: {
            type: String,
            required: false,
            default: ''
        },
        dark: {
            type: Boolean,
            default: false
        },
        persistentHint: Boolean
    }
}
</script>

<template>
    <div>
        <label class="input_label" v-if="!hideLabel" :for="id">{{ label }}</label>
        <v-text-field
            class="input_text"
            :class="{ 'input-text--dark': dark }"
            outlined
            solo
            flat
            dense
            @input="$emit('input', $event)"
            :value="value"
            :label="label"
            :id="id"
            :hint="hint"
            persistent-hint
            hide-details
            v-bind="$attrs"
            v-on="$listeners"
        >
            <template v-slot:prepend-inner v-if="$slots['prepend-inner']">
                <slot name="prepend-inner"></slot>
            </template>
            <template v-slot:prepend v-if="$slots.prepend">
                <slot name="prepend"></slot>
            </template>
            <template v-for="(slotContent, slotName) in $slots" :slot="slotName">
                <slot :name="slotName"></slot>
            </template>
        </v-text-field>
    </div>
</template>
