<template>
    <div>
        <v-menu
            v-if="$vuetify.breakpoint.smAndUp"
            content-class="rounded-sm"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="650"
            :nudge-bottom="20"
            offset-y
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn small icon @click="menu = !menu">
                    <v-icon color="primary">mdi-card-bulleted-outline</v-icon>
                </v-btn>
            </template>
            <ChatWindow :close="() => (menu = false)" />
        </v-menu>

        <!-- Полноэкранный режим для мобильных -->
        <v-dialog v-else v-model="menu" fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition">
            <ChatWindow :close="() => (menu = false)" />
        </v-dialog>
        <v-btn small icon @click="menu = !menu"  v-if="!$vuetify.breakpoint.smAndUp">
            <v-icon color="primary">mdi-card-bulleted-outline</v-icon>
        </v-btn>
    </div>
</template>

<script>
import ChatWindow from "@/components/OpenAI/ChatWindow.vue";

export default {
    name: "OpenAIChat",
    components: { ChatWindow },

    data() {
        return {
            menu: false,
        };
    },
};
</script>
