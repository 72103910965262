<template>
    <div class="app" v-if="loaded">
        <app-top-menu
            :show-app-bar="showAppBar"
            :key="sidebarKey"
            :drawer="drawer"
            :mobileMenuActive="menuActive"
            class="main-header"
            @nav-toggle="navToggle"
        ></app-top-menu>
        <main class="main" ref="main">
            <div class="main__side sidebar main-nav" :class="{'collapsed': !menuActive}">
                <div class="main-nav__inner">
                    <div class="logo">
                        <template v-if="$route.name === 'uved'">
                            <img class="compact" :src="`/img/${logoDir}/logo.svg`" alt="logo"/>
                        </template>
                        <template v-else>
                            <img class="compact" :src="`/img/${logoDir}/logo.svg`" @click="goToMenu(home)" alt="logo"/>
                        </template>
                    </div>
                    <div class="hide-mobile-menu" @click="navToggle">
                        <v-icon color="primary" size="18">icon-arrow-left</v-icon>
                    </div>
                    <div class="nav">
                        <RouterLink :to="{name:'profile'}" class="current-user" ref="current-user">
                            <User :item="{name:userName, photo: userAvatar}">
                                <template v-slot:position>
                                    <span>{{ userEmployeePosition }}</span>
                                </template>
                            </User>
                        </RouterLink>
                        <ul ref="main-menu">
                            <li v-for="(item, k) in navItems" class="nav__item nav-item" :key="k" v-show="item.visible">
                                <RouterLink class="nav-item__link" :to="item.route" @click.native="onNavItemClick">
                                    <i v-if="item.icon" class="nav-item__icon" :class="item.icon"></i>
                                    <i v-else class="nav-item__icon icon icon-circle-small"></i>
                                    <i v-if="item.new > 0" class="nav-item__has-notification"></i>
                                    <span>{{ $t(item.title) }}</span>
                                </RouterLink>
                            </li>
                        </ul>
                        <div class="copyright" ref="copyright">
                            <span>&copy; {{ new Date().getFullYear() }} Leen Platform<br>{{ $t('copyright') }}</span>
                        </div>
                    </div>
                    <div class="logo-footer">
                        <template v-if="$route.name === 'uved'">
                            <img class="compact" :src="`/img/${logoDir}/logo-mobile.svg`" alt="logo"/>
                        </template>
                        <template v-else>
                            <img class="compact" :src="`/img/${logoDir}/logo-mobile.svg`" @click="goToMenu(home)" alt="logo"/>
                        </template>
                    </div>
                </div>
            </div>

            <div class="main__menu-overlay" :class="{'active': menuActive}" @click="navToggle"></div>

            <div class="main__content" ref="mainContent">
                <div class="main__inner">
                    <transition name="fade" mode="out-in" >
                        <router-view :key="componentKey" id="scrolling-techniques-7" max-height="400" ></router-view>
                    </transition>
                </div>
            </div>
        </main>
        <v-dialog
            v-model="dialogImportFileError"
            v-if="dialogImportFileError"
            width="600px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ $t('errors_found_uploaded_file') }}</span>
                </v-card-title>
                <v-card-text v-for="(error, i) in textImportFileError" :key="i" v-html="error">
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="dialogImportFileError = false"
                    >
                        {{ $t('close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="information"
            :vertical="false"
            :timeout="-1"
            color="primary"
        >
            <span @click="informationOpen(information_date);information = false" class="cursor_pointer">
                {{ information_date.message }}
            </span>
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="information = false"
                >
                    {{$t('close')}}
                </v-btn>
                <v-btn
                    text
                    v-bind="attrs"
                    @click="informationOpen(information_date); information = false"
                >
                    {{$t('open')}}
                </v-btn>

            </template>
        </v-snackbar>

        <v-dialog
            transition="dialog-bottom-transition"
            persistent
            max-width="700"
            v-model="dialogSocket"
            v-if="dialogSocket"
        >
            <v-card>
                <v-toolbar class="dialog_toolbar" elevation="0">
                    <div class="ma-auto ml-0">
                        <span>{{ dialog.title }}</span>
                    </div>
                    <div>
                        <v-icon @click="dialogSocket = false" :title="$t('close')">mdi-close</v-icon>
                    </div>
                </v-toolbar>
                <v-card-text >
                    <v-row >
                        <v-col class="py-0 pt-5" cols="12">
                            <v-list
                                two-line
                                subheader
                                v-if="dialog.data_text !== undefined"
                            >
                                <v-list-item v-for="(item, index)  in dialog.data_text" :key="'dialog_'+item.value+'_'+index">
                                    <v-list-item-avatar>
                                        <v-icon color="primary">
                                            mdi-card-text
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.title"></v-list-item-title>
                                        <v-list-item-subtitle v-text="item.text"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <div  v-if="dialog.data_text_show !== undefined">{{dialog.data_text_show}}</div>
                        </v-col>

                    </v-row>
                </v-card-text>
                <v-card-actions class="pt-5">
                    <v-spacer></v-spacer>
                    <v-btn v-if="dialog.data.route === 'refresh'" text color="primary" @click="dialogSocket = false;$router.go(0)">
                        {{ $t('refresh') }}
                    </v-btn>
                    <v-btn v-else text color="primary" @click="goToLink(dialog.data)">
                        {{ $t('open') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogCameraCapture"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card height="100%">
                <v-card-title v-if="false" class="headline">Сделайте снимок</v-card-title>

                <v-card-text style="height: 100%" class="mx-0 px-0 my-0 py-0">
                    <CameraCapture @photo-close="dialogCameraCapture=false" @photo-taken="handlePhotoTaken" :dialog="dialogCameraCapture"/>
                </v-card-text>


            </v-card>
        </v-dialog>

        <v-overlay :value="!WorkDayStart && lang !== 'zh' && businessDayEntrance === 'true'"
                   absolute
                   opacity="0.9"
                   style="z-index: 200"

        >
            <v-btn v-show="false" color="primary" block class="mt-5" @click="openWorkDay('start')" v-if="!WorkDayStart">{{$t('get_to_work')}}</v-btn>

        </v-overlay>
    </div>
</template>

<script>
import Vue from 'vue'
import mixin from '../../plugins/mixin'
import "@/styles/icons/icons.css";
import "@/styles/leen-tech.scss";

Vue.mixin(mixin)

import NavMixin from '@/plugins/mixins/Nav';
import Echo from "laravel-echo";
import {getMessaging, getToken} from "firebase/messaging";
import {mapGetters, mapActions, mapState} from "vuex"
import AppTopMenu from "@/components/Leentech/AppTopMenu"
import User from "@/components/User.vue";
import notifications from "../Notifications.vue";
import CameraCapture from '@/components/CameraCapture.vue';

export default {
    name: "LeenTechLayout",
    components: {
        User,
        AppTopMenu,
        CameraCapture

    },
    mixins: [
        NavMixin
    ],
    props: {
        source: String,
        push: Object
    },
    provide: function () {
        return {
            forceRerender: this.forceRerender,
            forceRerenderSidebar: this.forceRerenderSidebar,
            setUserData: this.setUserData,
        }
    },
    data() {
        return {
            loading: false,
            loaded: false,
            drawer: true,
            componentKey: 0,
            sidebarKey: 0,
            friend: null,
            dialogImportFileError: false,
            textImportFileError: null,
            showAppBar: true,
            information: false,
            information_date: {},
            dialogSocket: false,
            dialog: {},
            tabs: 0,
            search_text: null,
            search_type: '',
            menuActive: false,
            fetchNotificationsTimeout: null,
            fetchNotificationGroupsTimeout: null,
            dialogCameraCapture: false,
            work_day_type: null,
        }
    },
    computed: {
        ...mapGetters([
            'WorkDayStatusId',
            'userBlock',
            'lang',
            'language',
            'defaultAvatar',
            'userAvatar',
            'userName',
            'userLogin',
            'userEmployeePosition',
            'userInitials',
            'theme',
            'WorkDayStatusId',
            'WorkDayStart',
            'businessDayEntrance'
        ]),

        logoDir() {
            return process.env.VUE_APP_LOGO_DIR ?? 'logo'
        },
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    mounted() {
        this.fetchMessages();
        this.appName = process.env.VUE_APP_NAME
        this.drawer = this.$vuetify.breakpoint.mdAndUp
        this.$auth
            .load()
            .then(() => {
                if (this.$auth.check() && this.$auth.user() && this.$auth.user().settings) {
                    this.setSettings(this.$auth.user().settings)
                    this.setUserId(this.$auth.user().id)
                    this.setUserUuid(this.$auth.user().uuid)
                    this.setUserName(this.$auth.user().name)
                    this.setUserInitials(this.$auth.user().name)
                    this.setUserPosition(this.$auth.user().position)
                    this.setEmployeePosition(this.$auth.user().employee_position)
                    this.setUserAvatar((this.$auth.user().photo ? this.$auth.user().photo : this.defaultAvatar))
                    this.setCountUnreadNotifications((this.$auth.user().countUnreadNotifications ? this.$auth.user().countUnreadNotifications : this.countUnreadNotifications))
                    this.listenChannelWebsocket()
                    //this.listenChannelFCM()
                }
                this.loaded = true
            })
        this.setLang()

        window.addEventListener('resize', this.align)
        this.align();

        this.fetchNotifications();
        this.fetchNotificationGroups();
    },

    destroyed() {
        window.removeEventListener('resize', this.align)
    },

    methods: {
        ...mapActions(['setWorkDayStatusId','setWorkDayStart','fetchNotificationGroups', 'fetchNotifications', 'setCountUnreadNotifications', 'setSettings', 'setLanguage', 'setUserName', 'setUserId', 'setUserUuid', 'setUserInitials', 'setUserPosition', 'setEmployeePosition', 'setUserAvatar']),
        ...mapActions('messages', ['fetchMessages']),

        openWorkDay(type){
            this.work_day_type = type;
            this.dialogCameraCapture = true
        },
        async workDay(data) {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.languages && this.languages[this.tab]) {
                formData.append('language', this.languages[this.tab])
            }


            if (data.geo_data && data.geo_data.latitude) {
                formData.append('latitude', data.geo_data.latitude)
            }
            else {
                this.$toastr.error(this.$t('wait_until_coordinates_of_the_location_are_determined'))
                return;
            }
            if (data.geo_data && data.geo_data.longitude) {
                formData.append('longitude', data.geo_data.longitude)
            }

            if (data.address) {
                formData.append('location', data.address)
            }

            if (data.photo) {
                if (data.photo.length > 250) {
                    var mimeType = this.getMimeType(data.photo)
                    var blob = this.dataURL64toBlob(data.photo)
                    if (mimeType && blob) {
                        formData.append('photo', blob, mimeType)
                    }
                } else {
                    this.$toastr.error(this.$t('take_photo'))
                    return;
                }
            }

            //  this.work_day_type = 'start';
            await this.$http
                .post(`admin/work_day/${this.work_day_type}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('work_day_has_been_changed'))
                    if(this.work_day_type === 'start'){
                        this.setWorkDayStart(true);
                    }
                    else {
                        this.setWorkDayStart(false);
                    }
                    if (res && res.body && res.body.data && res.body.data.work_day_status_id) {
                        this.setWorkDayStatusId(res.body.data.work_day_status_id);
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('work_day_has_not_been_changed'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        },

        handlePhotoTaken(data) {
            // Закрываем диалог после получения фото
            this.dialogCameraCapture = false;
            // Вставьте код для отправки фото в базу данных через API
            this.workDay(data);
        },

        fetchNotificationGroupsWithDelay() {
            let self = this;

            return new Promise((resolve, reject) => {
                if(self.fetchNotificationGroupsTimeout) {
                    clearTimeout(self.fetchNotificationGroupsTimeout)
                }

                self.fetchNotificationGroupsTimeout = setTimeout(() => {
                    self.fetchNotificationGroups()
                        .then(() => {
                            resolve();
                        }).catch(() => {
                            reject();
                        });
                }, 1000)
            })
        },

        fetchMessagesWithDelay() {
            let self = this;

            return new Promise((resolve, reject) => {
                if(self.fetchMessagesTimeout) {
                    clearTimeout(self.fetchMessagesTimeout)
                }

                self.fetchMessagesTimeout = setTimeout(() => {
                    self.fetchMessages()
                        .then(() => {
                            resolve();
                        }).catch(() => {
                            reject();
                        });
                }, 1000)
            })
        },

        fetchNotificationWithDelay() {
            let self = this;

            return new Promise((resolve, reject) => {
                if(this.fetchNotificationsTimeout) {
                    clearTimeout(this.fetchNotificationsTimeout)
                }

                this.fetchNotificationsTimeout = setTimeout(() => {
                    self.fetchNotifications()
                        .then(() => {
                            resolve();
                        }).catch(() => {
                            reject();
                        });
                }, 1000)
            });
        },

        goToLink(data) {
            this.dialogSocket = false;
            let route = {
                "name": data.route,
                "params": data.params,
            }
            this.$router.push(route);
        },

        informationOpen(item) {
            if (item.data && item.data.type && item.data.route) {

                if ((item.data.type === 'profile' || item.data.type === 'invoice' || item.data.type === 'tracking_updates' || item.data.type === 'invitation_accepted') && item.data.route !== '') {

                    this.$router.push({
                        name: item.data.route,
                    })
                } else if (item.data.type === 'document.show' ||  item.data.type === 'task.show' || item.data.type === 'deal.show' || item.data.type === 'case.show') {

                    this.$router.push({
                        name: item.data.route,
                        params: item.data.params
                    })
                } else if (item.data.type === 'discuss_chat' || item.data.type === 'discuss_chat') {

                    this.$router.push({
                        name: item.data.route,
                        params: item.data.params
                    })
                }
            }
        },

        handleScroll() {
            if (window.scrollY > 0) {
                this.showAppBar = false;
            } else {
                this.showAppBar = true;
            }
        },

        setLang2() {
            if (this.$auth.check() && this.$auth.user() && this.$auth.user().language) {
                document.documentElement.lang = this.$auth.user().language
                this.setLanguage(this.$auth.user().language)
            }
            if (typeof this.$i18n.locale !== 'undefined') {
                this.$i18n.locale = document.documentElement.lang
            }
            this.$moment.locale(this.$i18n.locale)
            if (typeof this.$vuetify.lang.current !== 'undefined') {
                this.$vuetify.lang.current = document.documentElement.lang
            }
        },

        getCookie(name) {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        },

        async setLang() {
            let language = this.getCookie('language')
            if (language == null && this.$auth.check() && this.$auth.user() && this.$auth.user().language) {
                document.documentElement.lang = this.$auth.user().language

            } else {
                document.documentElement.lang = language
            }
            this.$vuetify.lang.current = document.documentElement.lang
            this.$i18n.locale = document.documentElement.lang
            this.setLanguage(document.documentElement.lang)
            if (typeof this.$i18n.locale !== 'undefined') {
                this.$i18n.locale = document.documentElement.lang
            }
            this.$moment.locale(this.$i18n.locale)
            if (typeof this.$vuetify.lang.current !== 'undefined') {
                this.$vuetify.lang.current = document.documentElement.lang
            }
            this.forceRerender()
        },
        setTheme(themeSelect) {
            let localTheme = localStorage.getItem('themeDark')
            localTheme = localTheme === 'true' ? true : (localTheme === 'false' ? false : null)
            const mediaTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
            this.$vuetify.theme.dark = (themeSelect !== undefined ? themeSelect : (localTheme !== null ? localTheme : mediaTheme))
            localStorage.setItem('themeDark', this.$vuetify.theme.dark)
            document.documentElement.setAttribute('class', (this.$vuetify.theme.dark ? 'dark' : 'light'))
        },
        forceRerender: function () {
            this.componentKey += 1
        },
        forceRerenderSidebar: function () {
            this.sidebarKey += 1
        },
        navToggle() {
            this.menuActive = !this.menuActive
            this.align()
        },
        onNavItemClick() {
            if (this.menuActive && this.$vuetify.breakpoint.xsOnly) {
                this.menuActive = false
            }
        },
        checkNotification() {
            var _this = this
            if (!("Notification" in window)) {
                console.log("This browser does not support desktop notification");
            } else if (Notification.permission === "granted") {
                console.log("Notification.permission granted");
                // If it's okay let's create a notification
                //var notification = new Notification("Hi there 1!")
                this.listenChannelFCM()
            } else if (Notification.permission !== 'denied' || Notification.permission === "default") {
                Notification.requestPermission(function (permission) {
                    console.log("User accepts, let's create a notification");
                    // If the user accepts, let's create a notification
                    if (permission === "granted") {
                        //var notification = new Notification("Hi there 2!")
                        _this.listenChannelFCM()
                    }
                })
            }
        },
        listenChannelFCM() {
            const messaging = getMessaging();
            getToken(messaging, {vapidKey: process.env.VUE_APP_FCM_VAPID_KEY})
                .then((token) => {
                    if (token) {
                        // Send the token to your server and update the UI if necessary
                        //console.log('Send the token to your server ', token);
                        console.log('Send the token to your server');
                        this.saveNotificationToken(token)
                    } else {
                        console.log('No registration token available. Request permission to generate one.');
                    }
                })
                .catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                });
        },
        saveNotificationToken(token) {
            const params = {
                token: token,
                type: 'browser'
            }
            this.$http
                .post("admin/device", params)
                .then((res) => {
                    console.log('Successfully saved notification token!')
                })
                .catch((err) => {
                    console.log('Error: could not save notification token')
                })
        },

        listenChannelWebsocket() {
            const self = this;

            if(window.Echo) { // чтобы не соединятся по несколько раз
                return;
            }

            window.Echo = new Echo({
                broadcaster: "pusher",
                key: process.env.VUE_APP_PUSHER_APP_KEY,
                cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
                disableStats: true,
                encrypted: true,
                enabledTransports: ["wss", "ws"],
                wsHost: this.$pusherServer,
                authorizer: (channel, options) => {
                    return {
                        authorize: (socketId, callback) => {
                            this.$http.post(`https://${this.$pusherServer}/api/broadcasting/auth`, {
                                socket_id: socketId,
                                channel_name: channel.name
                            })
                                .then(response => {
                                    callback(false, response.data)
                                })
                                .catch(error => {
                                    callback(true, error)
                                })
                        }
                    }
                }
            })

            window.Echo
                .private(`App.Models.Admin.${this.$auth.user().id}`)
                .listen('.Event.Admin.Messages', (event) => {
                    if (event.data.type.indexOf('file_xml') !== -1) {
                        this.$toastr.success(`${event.title} ${event.message}`)
                    } else if (event.data.type.indexOf('notify') !== -1) {
                        this.$toastr.success(`${event.title} ${event.message}`)
                    } else if (event.data.type.indexOf('error_xml_file') !== -1) {
                        this.$toastr.error(`${event.title} ${event.message}`)
                        this.dialogImportFileError = true
                        this.textImportFileError = event.data.errors;
                    } else if (event.data.socket.indexOf('information') !== -1) {
                        // console.log('====================================');
                        // console.log(event);
                        // console.log('====================================');
                        this.information = true
                        this.information_date = event

                        self.fetchNotificationGroupsWithDelay();
                    }
                })
                .listen('.Event.Admin.Dialog', (event) => {
                    if (event.data.dialog.indexOf('case_send') !== -1) {
                        this.dialog = {"title": event.title, "text": event.message, "data": event.data, "data_text": event.text}
                        this.dialogSocket = true;
                    } else if (event.data.dialog.indexOf('unlock_admin') !== -1) {
                        this.dialog = {"title": event.title, "text": event.message, "data": event.data, "data_text_show": event.message}
                        this.dialogSocket = true;
                    }
                })
                .listen(`.Event.DiscussChat`, (event) => {
                    self.fetchNotificationGroupsWithDelay();
                })
                //todo: сделать универсально. чтобы при добавлении новых отделов не приходилось добавлять новые слушатели
                .listen(`.Event.TaskChat`, (event) => {
                    self.onNewMessage()
                })
                .listen(`.Event.DealChat`, (event) => {
                    self.onNewMessage()
                })
                .listen(`.Event.UvedChat`, (event) => {
                    self.onNewMessage()
                })
                .listen(`.Event.Chat.34`, (event) => {
                    self.onNewMessage()
                })
                .listen(`.Event.ChangeWorkDayStatus`, (event) => {
                    this.setWorkDayStatusId(event.work_day_status_id);
                })

            window.Echo
                .join(`App`)
                .here(users => {
                    this.$store.commit('admins/setAdmins', users);
                })
                .joining(user => {
                    this.$store.dispatch('admins/addAdmin', user);
                })
                .leaving(user => {
                    this.$store.dispatch('admins/removeAdmin', user);
                });
        },

        onNewMessage() {
            this.fetchNotificationGroupsWithDelay();
            this.fetchMessagesWithDelay();
        },

        setUserData() {
            this.setSettings(this.$auth.user().settings)
            this.setUserId(this.$auth.user().id)
            this.setUserUuid(this.$auth.user().uuid)
            this.setUserName(this.$auth.user().name)
            this.setUserInitials(this.$auth.user().name)
            this.setLanguage(this.$auth.user().language)
            this.setUserAvatar(this.$auth.user().avatar)
            this.setEmployeePosition(this.$auth.user().employee_position)
            this.setCountUnreadNotifications(this.$auth.user().countUnreadNotifications)

            //this.checkNotification()
            this.listenChannelWebsocket()

        },

        align() {
            if(this.$refs["main-menu"] && this.$refs["current-user"]) {

                let mq = window.matchMedia('(max-width: 600px)')

                this.$refs["main-menu"].querySelectorAll('.nav-item__icon').forEach((item) => {
                    item.style.marginLeft = null
                })
        //         this.$refs["current-user"].$el.style.marginLeft = '0'
        //         this.$refs["current-user"].$el.style.marginRight = '0'
        //         // this.$refs["copyright"].style.marginLeft = '0'
        //         // this.$refs["copyright"].style.marginRight = '0'
        //
                if (mq.matches) {
                    let maxWidth = 150;

                    if (this.$refs["main-menu"] && maxWidth < this.$refs["current-user"].offsetWidth) {
                        maxWidth = this.$refs["main-menu"].offsetWidth
                    }

                    // if (this.$refs["current-user"] && maxWidth < this.$refs["current-user"].$el.offsetWidth) {
                    //     maxWidth = this.$refs["current-user"].$el.offsetWidth
                    // }

                    // if (this.$refs["copyright"] && maxWidth < this.$refs["copyright"].offsetWidth) {
                    //     maxWidth = this.$refs["copyright"].offsetWidth
                    // }

                    this.mobileMenuWidth = maxWidth

                    let mobileMenuPaddingLeft = document.documentElement.clientWidth / 2 - this.mobileMenuWidth / 2

                    this.$refs["main-menu"].querySelectorAll('.nav-item__icon').forEach((item) => {
                        item.style.marginLeft = (mobileMenuPaddingLeft - 34) + 'px'
                    })
                    // this.$refs["current-user"].$el.style.marginLeft = mobileMenuPaddingLeft + 'px'
                    // this.$refs["current-user"].$el.style.marginRight = mobileMenuPaddingLeft + 'px'
                    // this.$refs["copyright"].style.marginLeft = mobileMenuPaddingLeft + 'px'
                    // this.$refs["copyright"].style.marginRight = mobileMenuPaddingLeft + 'px'
                }
            }
        }
    }
}
</script>


<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

$side-width-collapsed: 60px;
$side-width: 212px;
$icon-size: 21px;
$icon-padding: $spacer * 3;
$menu-icon-size: $icon-padding * 2 + $icon-size;


.app {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

#scrolling-techniques-7 {
    width: 100%;
}


.main-header {
    flex-shrink: 0;
}

.main {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100vh - var(--header-height-mobile));
    position: relative;
    padding: 0;
    margin: 0;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
        height: calc(100vh - var(--header-height));
    }
}

.main-footer {
    height: var(--footer-height-mobile);

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
        height: var(--footer-height);
    }
}

.main__content {
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: auto;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
        padding-left: $side-width-collapsed;
            }
}


.main__inner {
    position: relative;
    display: flex;
    flex-grow: 1;
    width: 100%;
}

.main-data-table {
    margin-bottom: $grid-gutter;
}

/* main-pagination */
.main-pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: $grid-gutter;

    .main-pagination__per-page {
        width: 100%;
    }

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
        flex-wrap: nowrap;
        justify-content: flex-end;
    }
}

.main-pagination__pagination {
    margin-left: -10px;
    margin-right: -10px;
}

.logo-footer {
    display: none;
    color: var(--primary);
    font-size: 28px;
    justify-content: center;
    padding: 0 $grid-gutter $grid-gutter $grid-gutter;
    width: 100%;
    margin: 0 auto;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.main-nav {
    top: 0;
    position: fixed;
    transition: all ease 300ms;
    z-index: 200;
    flex-shrink: 0;
    transform: translateX(0);
    width: 100%;
    bottom: 0;


    .main-nav__inner {
        background-color: var(--body);
        display: flex;
        flex-direction: column;
        scrollbar-width: thin;
        z-index: 101;
        position: relative;
        transition: all ease 300ms;
        height: 100%;
        border-right: 1px solid #eee;

        .logo {
            border-bottom: 1px solid #eee;
            width: 100%;
            height: 60px;
            padding-top: $grid-gutter / 2;
            padding-bottom: $grid-gutter / 2;

            img, svg {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    &.collapsed {
        transform: translateX(-100%);

        .main__menu-overlay {
            background-color: rgba(0, 0, 0, 0.0);
        }
    }

    .nav {
        margin: 0;
        padding: 0;
        flex-grow: 1;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: none;
        border-bottom: 1px solid #eee;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media #{map-get($display-breakpoints, 'sm-and-up')} {
            padding-top: $grid-gutter * 1.5;
            margin-bottom: $grid-gutter  * 1.5;
        }

        ul {
            display: inline-block;
            list-style: none;
            margin: 0;
            padding: $grid-gutter / 2 0 ;
            width: 100%;
        }

        & > ul {
            border-top: 1px solid #eee;
            border-bottom: 1px solid #eee;

            @media #{map-get($display-breakpoints, 'sm-and-up')} {
                border: none;
            }
        }
    }

    .nav-item {
        margin: $grid-gutter / 2 0;
        padding: 0;
        width: 100%;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &.active {
            ul {
                display: block;
                background: var(--bg-active);
            }
        }

        &.parent.active {
            & > .nav-item__link {
                background-color: var(--primary);
                color: #fff !important;
            }
        }

        @for $i from 1 through 20 {
            &:nth-child(#{$i}) {
                span {
                    transition-delay: $i * 0.02s;
                }
            }
        }
    }

    .nav-item__link {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 0;
        text-decoration: none;
        font-weight: 400;
        transition: all 0.3s;
        position: relative;
        color: #969696 !important;
        height: 45px;
        text-align: left;

        span {
            overflow: hidden;
            transition: all ease 0.3s;
            opacity: 1;
            transform: translateX(0);
            margin-left: 12px;
            display: block;
        }

        &:hover {
            color: var(--primary) !important;
        }

        &.active {
            color: var(--primary) !important;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 2px;
                height: 100%;
                background: var(--primary);
                border-radius: 2px;
            }
        }

        &.collapse-toggle {
            display: none;
            border-bottom: 1px solid #ccc;

            @media #{map-get($display-breakpoints, 'sm-and-up')} {
                display: flex
            }
        }

        @media #{map-get($display-breakpoints, 'sm-and-up')} {
            padding-left: 0;
        }
    }

    .nav-item__has-notification {
        width: 6px;
        height: 6px;
        display:block;
        background-color: var(--primary);
        border-radius: 100%;
        position: absolute;
        left: 11px;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
    }

    .nav-item__icon {
        width: $menu-icon-size;
        height: $menu-icon-size;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        font-size: 21px;
        padding: $icon-padding;

        @media #{map-get($display-breakpoints, 'sm-and-up')} {
            margin-left: ($side-width-collapsed - $menu-icon-size) / 2;
            margin-right: ($side-width-collapsed - $menu-icon-size) / 2;
        }
    }

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
        width: auto;

        .main-nav__inner {
            width: $side-width-collapsed;
            height: calc(100vh - var(--header-height));
        }

        &.collapsed {
            transform: translateX(0);

            .main-nav__inner {
                transform: none;
            }
        }

        .nav-item__link {
            span {
                opacity: 0;
                transform: translateX(100px);
            }
        }

        &:hover {
            .main-nav__inner {
                width: 215px;
            }

            .nav-item__link {
                span {
                    opacity: 1;
                    transform: translateX(-10px);
                }
            }

            .logo-footer {
                display: flex;
            }
        }
    }

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
        position: absolute;

        .logo {
            display: none;
        }
    }
}

.main__menu-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 150;
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 0.4s ease;
    width: 0;

    &.active {
        opacity: 1;
        transform: translateX(0);
        width: 100%;
    }
}

.hide-mobile-menu {
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 60px;

    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary);
    cursor: pointer;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
        display: none;
    }
}

.current-user {
    text-decoration: none;
    padding: $spacer * 4 0;
    display: flex;
    justify-content: center;
    width: 100%;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
        display: none;
    }
}

.copyright {
    display: inline-block;
    padding: $grid-gutter / 2 0;
    text-decoration: none;
    color: #969696;
    font-size: 14px;
    text-align: center;
    width: 100%;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
        display: none;
    }
}
</style>
