<template>
    <v-card class="chat-card">
        <v-toolbar class="dialog_toolbar" elevation="0">
            <div class="ma-auto ml-0">
                <span>{{ $t("just_ask") }}</span>
            </div>
            <v-btn icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>

        <v-card-text class="chat-content" ref="chatContent">
            <div v-for="(message, index) in messages" :key="index" :class="['chat-bubble', message.type]">
                <span v-html="formattedText(message.text)"></span>
            </div>
        </v-card-text>

        <v-card-actions class="pb-3 pt-4 chat-actions">
            <div class="generating_response" v-if="loading">
                {{$t('generating_response')}}
            </div>
            <v-textarea
                :rows="2"
                name="chat_message"
                outlined
                class="chat-input v-textarea--no-resize"
                @keydown.enter.prevent="sendMessage"
                v-model="userMessage"
                :label="$t('enter_message')"
                hide-details
                :disabled="loading"
            >
                <template v-slot:append>
                    <v-btn color="primary" dark icon @click="sendMessage" :loading="loading">
                        <v-icon>mdi-send</v-icon>
                    </v-btn>
                </template>
            </v-textarea>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: ["close"],
    data() {
        return {
            userMessage: "",
            loading: false,
            messages: [{text: this.$t('hi_how_can_i_help'), type: "bot"}]
        };
    },
    methods: {
        formattedText(text) {
            return text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
        },
        scrollToBottom() {
            this.$nextTick(() => {
                const chatContent = this.$refs.chatContent;
                if (chatContent) {
                    chatContent.scrollTop = chatContent.scrollHeight;
                }
            });
        },
        async sendMessage(event) {
            if (event.shiftKey) {
                this.userMessage += "\n";
                return false;
            }
            if (!this.userMessage.trim()) return;

            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            if (this.userMessage) {
                formData.append('text', this.userMessage)
            }

            this.messages.push({text: this.userMessage, type: "user"});
            this.userMessage = ""
            this.scrollToBottom();
            await this.$http
                .post('admin/openai/ask', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    if (res && res.body && res.body.data && res.body.data.message) {
                        this.messages.push({text: res.body.data.message, type: "bot"});
                        this.scrollToBottom();
                    } else {
                        this.$toastr.error(this.$t('error_receiving_data'))
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('error_receiving_data'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
    }
};
</script>

<style scoped>

.chat-card {
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    overflow: hidden;
    background: #ffffff;
}


/* Фиксированный заголовок */
.dialog_toolbar {
    flex: 0 !important;
    border: 0 !important;
}


/* Контент чата (растягивается) */
.chat-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    min-height: 500px;
    height: 500px;
    margin-bottom: 10px;
}

/* Фиксированное поле ввода */
.chat-actions {
    background-color: #F9F9F9;
    position: relative;
}

.wave-text {
    font-size: 2rem;
    font-weight: bold;
    background: linear-gradient(90deg, #000, #000, #000, #000, #000);
    background-size: 200% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: wave 3s linear infinite;
}

@keyframes wave {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 200% 0;
    }
}

/* Поле ввода */
.chat-input {
    flex: 1;
}

/* Блоки сообщений */
.chat-bubble {
    max-width: 80%;
    padding: 12px 16px;
    margin: 5px 0;
    border-radius: 16px;
    font-size: 15px;
    word-break: break-word;
    white-space: pre-wrap;
    word-wrap: break-word;
}

/* Сообщение пользователя */
.user {
    align-self: flex-end;
    background: #007aff;
    color: white;
    border-bottom-right-radius: 4px;
}

/* Сообщение бота */
.bot {
    align-self: flex-start;
    background: #e1e1e1;
    color: black;
    border-bottom-left-radius: 4px;
}

.generating_response{
    position: absolute;
    top: -25px;
    left: 10px;
    font-size: 12px
}

/* 🔹 Адаптация для мобильных */
@media (max-width: 768px) {

    .chat-card {
        width: 100%;
        max-width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        background: #ffffff;


    }

    .chat-card {
        border-radius: 0;
    }

    .chat-bubble {
        font-size: 14px;
        padding: 10px;
        max-width: 90%;
    }

    .chat-actions {
        padding: 4px;
    }

    .chat-input {
        font-size: 14px;
    }

    .chat-content {
        height: auto;
    }
}
</style>
